import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { MapWrapper, Map, ContactContent } from '../assets/styles/index'
import { ContactDetails, Communication, RouteToSaltMine } from '../components'
import SEO from '../components/seo'

const ContactPage = () => {
  const intl = useIntl();

  return(
    <>
      <SEO title="Contact" />
      <MapWrapper>
        <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1282.7031837661132!2d20.060061358311735!3d49.98499031948303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471641da73ab0e1f%3A0x47851220c7550db2!2sApartamenty%20KP%20Claudia!5e0!3m2!1spl!2spl!4v1576155531599!5m2!1spl!2spl" />
      </MapWrapper>
      <ContactContent ru={intl.locale}>
        <ContactDetails />
        <Communication />
        <RouteToSaltMine />
      </ContactContent>
    </>
  )
}

export default ContactPage